<template>
  <div style="font-size: 14px">
    <div class="select-container">
      <span class="select-title">已选:</span>
      <div class="tag-container">
        <el-tag
          v-for="tag in showList"
          :key="tag.id"
          type="success"
          :closable="view"
          @close="closeTag(tag)"
        >
          {{ tag.mergeName }}
        </el-tag>
      </div>
      <!-- <i
        @click="changeArrow"
        v-if="showArrow"
        class="arrow-icon"
        :class="clickArrow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
        ><span>{{ clickArrow ? "展开" : "收起" }}</span></i
      > -->
    </div>
    <div
      v-if="showTagContent"
      ref="allTagBox"
      :class="openAll ? '' : 'allBigBox'"
      :style="{ height: moreThree ? '' : 'auto !important' }"
    >
      <div class="item-container" v-for="item in tagList" :key="item.id">
        <div class="item-content">
          <span class="item-title">{{ item.cateName }}:</span>
          <div class="item-tag">
            <el-tooltip v-for="child in item.showkmCategoryLineList"
              :key="child.id" class="item" effect="dark" :content="child.cateLineName" placement="bottom">
            <el-tag
              :type="child.isClick ? 'info' : ''"
              @click="clickItemTag(child, item.cateName)"
              >{{ child.cateLineName }}</el-tag
            >
          </el-tooltip>
          </div>
          <div
            v-if="item.showArrow"
            class="arrow"
            @click="changeItemArrow(item)"
          >
            <i
              class="arrow-icon"
              :style="{ color: themeColor }"
              :class="
                item.clickArrow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
              "
            ></i>
            <span :style="{ color: themeColor }">{{
              item.clickArrow ? "展开" : "收起"
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="openAll"
      @click="openAllOption"
      :style="{ color: themeColor }"
      v-if="showTagContent && tagList.length > 3"
    >
      <span v-if="openAll">收起</span><span v-else>展开</span>全部选项<i
        :class="openAll ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
        style="margin-left: 12px"
      ></i>
    </div>
  </div>
</template>

<script>
import { getListAndChildren } from "@/api/knowledgeClassifcation/knowledge";
import { mapGetters } from "vuex";
import { getDictionary } from "@/api/system/dictbiz";
export default {
  props: {
    selectedTagId: {
      type: String,
      default: "",
    },
    view: {
      type: Boolean,
      default: true,
    },
    showTagContent: {
      type: Boolean,
      default: true,
    },
    isCheck: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectTags: [],
      showList: [],
      showArrow: false,
      clickArrow: true,
      openAll: false,
      tagList: [],
      showNum: 10,
      knowledgeActive: 0,
      knowledgeValue: "",
    };
  },
  components: {},
  computed: {
    ...mapGetters(["themeColor"]),
    moreThree() {
      let Boolean = false;
      if (this.tagList.length >= 3) {
        Boolean = true;
      }
      return Boolean;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.getShowNum();
    });
  },
  methods: {
    chooseKnowledge(e) {
      this.showList = [];
      this.selectTags = [];
      this.$emit("select-tag", this.selectTags);
      this.getList(e);
    },
    openAllOption() {
      this.openAll = !this.openAll;
      if (this.openAll) {
        this.$refs.allTagBox.style.cssText = "height: auto";
        this.tagList.forEach((item) => {
          if (item.clickArrow == true) {
            item.clickArrow = false;
            item.showkmCategoryLineList = item.kmCategoryLineList;
          }
        });
      } else {
        this.$refs.allTagBox.style.cssText = "";
        this.tagList.forEach((item) => {
          if (item.clickArrow == false) {
            item.clickArrow = true;
            item.showkmCategoryLineList = this.showItemList(
              item.kmCategoryLineList
            );
          }
        });
      }
    },
    getShowNum() {
      let contentWidth =
        document.getElementsByClassName("select-container")[0].scrollWidth;
      let tagContent = contentWidth - 194;
      this.showNum = Math.floor(tagContent / 112);
    },
    getList(knowledgeClassify) {
      getListAndChildren(knowledgeClassify).then((res) => {
        this.tagList = res.data.data;
        this.tagList.forEach((item) => {
          if (item.kmCategoryLineList.length > 0) {
            item.kmCategoryLineList.forEach((chi) => {
              this.$set(chi, "isClick", false);
            });
            if (item.kmCategoryLineList.length > this.showNum) {
              item.showkmCategoryLineList = this.showItemList(
                item.kmCategoryLineList
              );
              this.$set(item, "showArrow", true);
              this.$set(item, "clickArrow", true);
            } else {
              item.showkmCategoryLineList = item.kmCategoryLineList;
              this.$set(item, "showArrow", false);
            }
            //回显已选择tag
            if (this.selectedTagId) {
              let selectedTagIds = this.selectedTagId.split(",");
              selectedTagIds.forEach((tag) => {
                item.kmCategoryLineList.forEach((child) => {
                  if (tag == child.id) {
                    child.isClick = true;
                    child.mergeName = item.cateName + "：" + child.cateLineName;
                    this.selectTags.push(child);
                  }
                });
              });
              this.showTagList();
            }
          }
        });
      });
    },
    showItemList(list) {
      let showList = [];
      for (let i = 0; i < this.showNum; i++) {
        showList.push(list[i]);
      }
      return showList;
    },
    showTagList() {
      // this.showList = [];
      // if (this.selectTags.length > this.showNum) {
      //   for (let i = 0; i < this.showNum; i++) {
      //     this.showList.push(this.selectTags[i]);
      //   }
      //   this.showArrow = true;
      // } else {
      //   this.showList = this.selectTags;
      //   this.showArrow = false;
      // }
      this.showList = this.selectTags;
    },
    clickItemTag(item, cateName) {
      if (this.view) {
        item.isClick = !item.isClick;
        if (item.isClick) {
          item.mergeName = cateName + "：" + item.cateLineName;
          if (this.isCheck) {
            if (this.selectTags.indexOf(item) == -1) {
              this.selectTags.push(item);
            }
          } else {
            if (this.selectTags.length > 0) {
              const index = this.selectTags.findIndex(obj => obj.cateId === item.cateId);
              if (index !== -1) {
                this.selectTags[index].isClick = false;
                // 如果id已存在，替换整个对象
                this.selectTags[index] = item;
              } else {
                // 如果id不存在，将对象推入数组
                this.selectTags.push(item);
              }
            } else {
              this.selectTags.push(item);
            }
          }
          this.$emit("select-tag", this.selectTags);
          this.showTagList();
        } else {
          this.closeTag(item);
        }
      }
    },
    changeArrow() {
      this.clickArrow = !this.clickArrow;
      if (this.clickArrow) {
        this.showTagList();
      } else {
        this.showList = this.selectTags;
      }
    },
    changeItemArrow(item) {
      item.clickArrow = !item.clickArrow;
      if (!item.clickArrow) {
        item.showkmCategoryLineList = item.kmCategoryLineList;
        this.$refs.allTagBox.style.cssText =
          "height:" + Number(this.$refs.allTagBox.clientHeight + 37) + "px";
      } else {
        this.$refs.allTagBox.style.cssText =
          "height:" + Number(this.$refs.allTagBox.clientHeight - 37) + "px";
        item.showkmCategoryLineList = this.showItemList(
          item.kmCategoryLineList
        );
      }
    },
    closeTag(tag) {
      tag.isClick = false;
      this.selectTags = this.selectTags.filter((item) => item.id !== tag.id);
      this.$emit("select-tag", this.selectTags);
      this.showTagList();
    },
    showItemTag() {},
  },
};
</script>

<style scoped lang='scss'>
.select-container {
  display: flex;
  align-items: center;
  padding-top: 5px;
  border: 1px solid #ccc;
  line-height: 30px;
  .select-title {
    width: 100px;
    text-align: right;
    padding-left: 12px;
    margin-right: 10px;
  }
  .tag-container {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    .el-tag {
      height: 32px;
      line-height: 32px;
      margin-right: 12px;
      margin-bottom: 5px;
      .el-icon-close {
        font-size: 16px;
      }
    }
  }
  .arrow-icon {
    cursor: pointer;
    font-size: 14px;
    color: #66b1ff;
    span {
      margin-left: 5px;
    }
  }
}
.item-container {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  .item-content {
    line-height: 30px;
    padding: 5px 12px 0 12px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    .item-tag {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-tag {
        height: 32px;
        line-height: 32px;
        margin-right: 12px;
        margin-bottom: 5px;
        cursor: pointer;
        width: 100px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .item-title {
      width: 100px;
      text-align: right;
      display: inline-block;
      margin-right: 10px;
    }
    .arrow {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .arrow-icon {
        font-size: 14px;
        color: #66b1ff;
      }
      span {
        font-size: 14px;
        margin-left: 5px;
        color: #66b1ff;
      }
    }
  }
}
.allBigBox {
  height: 129px;
  overflow: hidden;
}
.openAll {
  width: calc(100% - 2px);
  text-align: center;
  border: 1px solid #ccc;
  border-top: none;
  height: 43px;
  line-height: 43px;
  cursor: pointer;
}
.item-name {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
}
</style>
