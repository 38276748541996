var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "font-size": "14px" } }, [
    _c("div", { staticClass: "select-container" }, [
      _c("span", { staticClass: "select-title" }, [_vm._v("已选:")]),
      _c(
        "div",
        { staticClass: "tag-container" },
        _vm._l(_vm.showList, function (tag) {
          return _c(
            "el-tag",
            {
              key: tag.id,
              attrs: { type: "success", closable: _vm.view },
              on: {
                close: function ($event) {
                  return _vm.closeTag(tag)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(tag.mergeName) + "\n      ")]
          )
        }),
        1
      ),
    ]),
    _vm.showTagContent
      ? _c(
          "div",
          {
            ref: "allTagBox",
            class: _vm.openAll ? "" : "allBigBox",
            style: { height: _vm.moreThree ? "" : "auto !important" },
          },
          _vm._l(_vm.tagList, function (item) {
            return _c("div", { key: item.id, staticClass: "item-container" }, [
              _c("div", { staticClass: "item-content" }, [
                _c("span", { staticClass: "item-title" }, [
                  _vm._v(_vm._s(item.cateName) + ":"),
                ]),
                _c(
                  "div",
                  { staticClass: "item-tag" },
                  _vm._l(item.showkmCategoryLineList, function (child) {
                    return _c(
                      "el-tooltip",
                      {
                        key: child.id,
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: child.cateLineName,
                          placement: "bottom",
                        },
                      },
                      [
                        _c(
                          "el-tag",
                          {
                            attrs: { type: child.isClick ? "info" : "" },
                            on: {
                              click: function ($event) {
                                return _vm.clickItemTag(child, item.cateName)
                              },
                            },
                          },
                          [_vm._v(_vm._s(child.cateLineName))]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
                item.showArrow
                  ? _c(
                      "div",
                      {
                        staticClass: "arrow",
                        on: {
                          click: function ($event) {
                            return _vm.changeItemArrow(item)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "arrow-icon",
                          class: item.clickArrow
                            ? "el-icon-arrow-down"
                            : "el-icon-arrow-up",
                          style: { color: _vm.themeColor },
                        }),
                        _c("span", { style: { color: _vm.themeColor } }, [
                          _vm._v(_vm._s(item.clickArrow ? "展开" : "收起")),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
    _vm.showTagContent && _vm.tagList.length > 3
      ? _c(
          "div",
          {
            staticClass: "openAll",
            style: { color: _vm.themeColor },
            on: { click: _vm.openAllOption },
          },
          [
            _vm.openAll
              ? _c("span", [_vm._v("收起")])
              : _c("span", [_vm._v("展开")]),
            _vm._v("全部选项"),
            _c("i", {
              class: _vm.openAll ? "el-icon-arrow-up" : "el-icon-arrow-down",
              staticStyle: { "margin-left": "12px" },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }